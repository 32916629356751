<template>
 <div>
    <b-modal
      id="CustomShippingModal"
      modal-class="modal-primary"
      :title="$t('Custom Shipping')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Custom Shipping-logo.png" height="150" /></p>
        <p>{{$t('The custom shipping option allows you to add more than one shipping method of your own, for example: store representative')}}</p>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.saveShippingService(custom_shipping_config, 'CustomShippingModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="custom_shipping_config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        
        <hr>
        <template v-for="(method, i) in custom_shipping_config.methods">
          <div :key="i">
            <h6>
              {{$t('Method')}} {{ i+1 }}
              <b-button
                variant="flat-danger"
                size="sm"
                @click="deleteMethod(i)"
              >
                <feather-icon
                  icon="XIcon"
                  size="15"
                />
              </b-button>
            </h6>

            <!-- Is Active -->
            <b-form-group
              :label="$t('Is Active ?')"
              label-for="v-is-active"
            >
              <b-form-checkbox
                class="custom-control-primary"
                v-model="method.is_active"
                name="is_active"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- Name -->
            <b-form-group
              :label="$t('Name')"
              label-for="v-name"
            >
              <b-form-input
                id="v-name"
                type="text"
                v-model="method.name"
              />
            </b-form-group>

            <!-- description -->
            <b-form-group
              :label="$t('Description')"
              label-for="v-description"
            >
              <b-form-input
                id="v-description"
                type="text"
                v-model="method.description"
                :placeholder="$t('Ship period 2-5 days')" 
              />
            </b-form-group>


            <!-- Price -->
            <b-form-group
              :label="$t('Price')"
              label-for="v-price"
            >
              <b-input-group>
                <b-form-input
                  id="v-price"
                  type="text"
                  v-model="method.price"
                />
                <b-input-group-append is-text>
                  {{$t('SAR')}}
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- Free shipping -->
            <b-form-group
              :label="$t('Free shipping when total order')"
              label-for="v-price"
            >
              <b-input-group>
                <b-form-input
                  id="v-price"
                  type="text"
                  v-model="method.free_shipping_value"
                  placeholder="999"
                />
                <b-input-group-append is-text>
                  {{$t('SAR')}}
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <!-- Cities -->
            <b-form-group
              :label="$t('Ship to cities')"
              label-for="v-cities"
            >
              <v-select
                v-model="method.cities"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="$parent.cities"
                :reduce="city => city.id"
                multiple
              />
            </b-form-group>

            <!-- COD Cities -->
            <b-form-group
              :label="$t('COD cities')"
              label-for="v-cod-cities"
            >
              <v-select
                v-model="method.cod_cities"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="$parent.cities"
                :reduce="city => city.id"
                multiple
              />
            </b-form-group>
            <hr>
          </div>
        </template>

        <b-button
          variant="outline-secondary"
          @click="addShippingMethod"
          size="sm"
        >
          <feather-icon
            icon="PlusIcon"
          />
          {{$t('Add Shipping Method')}}
        </b-button>

        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  data() {
    return {
      custom_shipping_config: {
        id: 1,
        is_active: false,
        methods: []
      }
    };
  },
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.custom_shipping_config = this.config
        this.custom_shipping_config.is_active = this.custom_shipping_config.is_active == "1" || this.custom_shipping_config.is_active == true ? true : false
        this.custom_shipping_config.methods.forEach(method => {
          method.is_active = method.is_active == "1" || method.is_active == true ? true : false
        });
      }
      this.$bvModal.show("CustomShippingModal")
    },
    addShippingMethod() {
      this.custom_shipping_config.methods.push({})
    },
    deleteMethod(i) {
      this.$delete(this.custom_shipping_config.methods, i)
    }
  }
}
</script>