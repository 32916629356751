<template>
 <div>
    <b-modal
      id="DhlModal"
      modal-class="modal-primary"
      :title="$t('DHL')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/DHL-logo.png" height="130" /></p>
        <p>{{$t('You can activate the shipping method with DHL with one click')}} 👌🏻</p>
        <p>{{$t('The shipping price is calculated automatically on the purchase page')}}, {{$t('Shipping price start from 60 SAR, Outside Saudi Arabia only')}}, {{$t('Cash on delivery not available')}}</p>
        <p class="text-danger">{{$t('It is recommended to issue the policy on the same day of the order so that there is no difference in the price of the shipment')}}</p>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.saveShippingService(dhl_config, 'DhlModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="dhl_config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
        <p>{{$t('Store Warehouse Address')}} :</p>
        <!-- City -->
        <b-form-group
          :label="$t('City')"
          label-for="v-city"
        >
          <v-select
            v-model="dhl_config.city"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="$parent.cities"
            :reduce="city => city.id"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Address in English')"
          label-for="v-address"
        >
          <b-form-input
            id="v-address"
            type="text"
            v-model="dhl_config.address"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Store name in English')"
          label-for="v-name-en"
        >
          <b-form-input
            id="v-name-en"
            type="text" 
            v-model="dhl_config.store_name_en"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Mobile')"
          label-for="v-mobile"
        >
          <b-form-input
            id="v-mobile"
            type="text" 
            v-model="dhl_config.store_phone"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Email')"
          label-for="v-email"
        >
          <b-form-input
            id="v-email"
            type="text" 
            v-model="dhl_config.store_email"
          />
        </b-form-group>

        <!-- Cities -->
        <b-form-group
          :label="$t('Ship to cities')"
          label-for="v-cities"
        >
          <v-select
            v-model="dhl_config.cities"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="$parent.cities"
            :reduce="city => city.id"
            multiple
          />
        </b-form-group>

        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  data() {
    return {
      dhl_config: {
        id: 4,
        is_active: false,
        cities: []
      }
    };
  },
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.dhl_config = this.config
        this.dhl_config.is_active = this.dhl_config.is_active == "1" || this.dhl_config.is_active == true ? true : false
        this.dhl_config.city = Number(this.dhl_config.city); // string to number

        let cities = [];
        this.dhl_config.cities.forEach(id => {
          cities.push(Number(id)) // string to number
        });
        this.dhl_config.cities = cities;
      }
      this.$bvModal.show("DhlModal")
    }
  }
}
</script>