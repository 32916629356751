<template>
  <div>
    <b-row class="match-height">
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Custom Shipping-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.CUSTOMSHIPPING).is_active == true"
              />
              {{$t('Custom Shipping')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.customShippingModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Smsa-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.SMSA).is_active == true"
              />
              {{$t('SMSA')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.smsaModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Aramex-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.ARAMEX).is_active == true"
              />
              {{$t('Aramex')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.aramexModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/DHL-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.DHL).is_active == true"
              />
              {{$t('DHL')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.dhlModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Beez-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.BEEZ).is_active == true"
              />
              {{$t('Beez')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.beezModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Jones-logo.png" height="80" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.JONES).is_active == true"
              />
              {{$t('Jones')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.jonesModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Barq Bullet-logo.png" height="50" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.BARQ_BULLET).is_active == true"
              />
              {{$t('Barq Bullet')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.barqBulletModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Adwar-logo.png" height="50" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.ADWAR).is_active == true"
              />
              {{$t('Adwar')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.adwarModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- <b-col md="3">
        <b-card no-body>
          <b-card-body class="text-center">
            <p>
              <b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Torod-logo.png" height="50" />
            </p>
            <h5>
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
                class="text-success"
                v-if="getShippingMethodById(shippingMethodsIds.TOROD).is_active == true"
              />
              {{$t('Torod')}}
            </h5>
            <b-button 
              size="sm"
              variant="outline-primary"
              pill
              @click="$refs.torodModal.openModal()"
            >
              <feather-icon
                icon="SettingsIcon"
                size="15"
              />
              {{$t('Settings')}}
            </b-button>
          </b-card-body>
        </b-card>
      </b-col> -->
    </b-row>
    <!-- Custom Shipping -->
    <custom-shipping-modal ref="customShippingModal" :config="getShippingMethodById(shippingMethodsIds.CUSTOMSHIPPING)"></custom-shipping-modal>
    <!-- Smsa -->
    <smsa-modal ref="smsaModal" :config="getShippingMethodById(shippingMethodsIds.SMSA)"></smsa-modal>
    <!-- Aramex -->
    <aramex-modal ref="aramexModal" :config="getShippingMethodById(shippingMethodsIds.ARAMEX)"></aramex-modal>
    <!-- Dhl -->
    <dhl-modal ref="dhlModal" :config="getShippingMethodById(shippingMethodsIds.DHL)"></dhl-modal>
    <!-- Beez -->
    <beez-modal ref="beezModal" :config="getShippingMethodById(shippingMethodsIds.BEEZ)"></beez-modal>
    <!-- Jones -->
    <jones-modal ref="jonesModal" :config="getShippingMethodById(shippingMethodsIds.JONES)"></jones-modal>
    <!-- barq buller -->
    <barq-bullet-modal ref="barqBulletModal" :config="getShippingMethodById(shippingMethodsIds.BARQ_BULLET)"></barq-bullet-modal>
    <!-- Torod -->
    <torod-modal ref="torodModal" :config="getShippingMethodById(shippingMethodsIds.TOROD)"></torod-modal>
    <!-- Adwar -->
    <adwar-modal ref="adwarModal" :config="getShippingMethodById(shippingMethodsIds.ADWAR)"></adwar-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'
import CustomShippingModal from './shipping-services/CustomShippingModal.vue'
import SmsaModal from './shipping-services/SmsaModal.vue'
import AramexModal from './shipping-services/AramexModal.vue'
import DhlModal from './shipping-services/DhlModal.vue'
import BeezModal from './shipping-services/BeezModal.vue'
import JonesModal from './shipping-services/JonesModal.vue'
import BarqBulletModal from './shipping-services/BarqBulletModal.vue'
import TorodModal from './shipping-services/TorodModal.vue'
import AdwarModal from './shipping-services/AdwarModal.vue'

export default {
  name: 'ShippingMethods',
  components: {
    CustomShippingModal,
    SmsaModal,
    AramexModal,
    DhlModal,
    BeezModal,
    JonesModal,
    BarqBulletModal,
    TorodModal,
    AdwarModal,
  },
  data() {
    return {
      shipping_methods_config: {},
      cities: [],
      isLoading: false,
      isLoadingSave: false,
      shippingMethodsIds: {
        CUSTOMSHIPPING: 1,
        SMSA: 2,
        ARAMEX: 3,
        DHL: 4,
        BEEZ: 7,
        JONES: 10,
        BARQ_BULLET: 12,
        TOROD: 13,
        ADWAR: 14,
      }
    }
  },
  mounted() {
    this.getShippingMethods();
    this.getCities();
  },
  methods: {
    getShippingMethods(){
      this.isLoading = true
      useJwt.get('/store/config/shipping-methods')
      .then((response) => {
        // console.log(response.data)
        this.shipping_methods_config = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    getShippingMethodById(id) {
      if (Object.keys(this.shipping_methods_config).length) {
        let shippingMethod = this.shipping_methods_config.find(method => { return method.id == id })
        if (shippingMethod) {
          return shippingMethod;
        }
      }
      return {};
    },
    saveShippingService(config, modalName) {
      this.isLoadingSave = true;
      config.store_id = this.$store.state.store.id;
      useJwt.post('/store/config/shipping-methods/save',config)
      .then((response) => {
        // console.log(response.data);
        this.isLoadingSave = false
        this.getShippingMethods()
        this.$root.showToast('success', 'save', '')
        this.$bvModal.hide(modalName)
      })
      .catch(error => {
        // console.log(response);
        this.isLoadingSave = false
        this.$root.showAlert('error', error.response.data.message)
      });
    },
    getCities(){
      useJwt.get('/cities/get-all')
      .then((response) => {
        // console.log(response.data)
        this.cities = response.data.data
      })
      .catch(response => {
        // console.log(response);
      });
    },
  }
}
</script>
