<template>
 <div>
    <b-modal
      id="JonesModal"
      modal-class="modal-primary"
      :title="$t('Jones')"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div>
        <p class="text-center"><b-img-lazy src="https://cdn.mhd.sa/asset/images/providers/Jones-logo.png" height="100" /></p>
        <p>جونز العالمية - تخدمكم في النقل المبرد لمعظم مدن المملكة</p>
        <hr>
      </div>
      <b-form @submit.prevent="$parent.saveShippingService(jones_config, 'JonesModal')">
        <b-form-group
          :label="$t('Is Active ?')"
          label-for="v-is-active"
        >
          <b-form-checkbox
            id="v-is-active"
            class="custom-control-primary"
            v-model="jones_config.is_active"
            name="is_active"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>

        <!-- description -->
        <b-form-group
          :label="$t('Description')"
          label-for="v-description"
        >
          <b-form-input
            id="v-description"
            type="text"
            v-model="jones_config.description"
            :placeholder="$t('Ship period 2-5 days')"
          />
        </b-form-group>

        <!-- Price -->
        <b-form-group
          :label="$t('Price')"
          label-for="v-price"
        >
          <b-input-group>
            <b-form-input
              id="v-price"
              type="text"
              v-model="jones_config.price"
              placeholder="45"
            />
            <b-input-group-append is-text>
              {{$t('SAR')}}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- free_shipping_value -->
        <b-form-group
          :label="$t('Free shipping when total order')"
          label-for="v-free_shipping_value"
        >
          <b-input-group>
            <b-form-input
              id="v-free_shipping_value"
              type="text"
              v-model="jones_config.free_shipping_value"
              placeholder="999"
            />
            <b-input-group-append is-text>
              {{$t('SAR')}}
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- Cities -->
        <b-form-group
          :label="$t('Ship to cities')"
          label-for="v-cities"
        >
          <v-select
            v-model="jones_config.cities"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="$parent.cities"
            :reduce="city => city.id"
            multiple
          />
        </b-form-group>

        <!-- api key -->
        <b-form-group
          :label="$t('Secret Key')"
          label-for="v-secret-key"
        >
          <b-form-input
            id="v-secret-key"
            type="text"
            v-model="jones_config.secret_key"
          />
        </b-form-group>

        <!-- Account Number -->
        <b-form-group
          :label="$t('Customer Id')"
          label-for="v-customer-id"
        >
          <b-form-input
            id="v-customer-id"
            type="text"
            v-model="jones_config.customer_id"
          />
        </b-form-group>

        <!-- submit -->
        <b-col cols="12" class="p-0 text-right">

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
          >
            <template v-if="!$parent.isLoadingSave">
              {{$t('Save')}}
            </template>
            <template v-if="$parent.isLoadingSave">
              {{$t('Saveing')}}...
              <b-spinner small />
            </template>
          </b-button>

        </b-col>
      </b-form>
    </b-modal>
 </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['config'],
  data() {
    return {
      jones_config: {
        id: 10,
        is_active: false,
        cities: []
      }
    };
  },
  methods: {
    openModal() {
      if (Object.keys(this.config).length > 0) {
        this.jones_config = this.config
      }
      this.$bvModal.show("JonesModal")
    }
  }
}
</script>